:root {
 /* -border-color: #300032;*/
 --primary: #191919;
 --primary-light: #3f3f3f;
 --primary-dark: #0f0f0f;
 --secondary: #d32f2f;
 --secondary-light: #ff6659;
 --secondary-dark: #9a0007;
 
 --border-color: #700900;
 --background-color: #282828;
 --container-color: #282828;
 --container-light: #2b2b2b;
 --highlight-color-1: #c43235;
 --text-color-1: #e6e6e8;
 --text-color-2: #cbcbcb;
}

.App {
  text-align: center;
  color: var(--text-color-2);
}
