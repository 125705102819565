#BurgerMenu {
  color: var(--on-background);
  border: none;
  border-radius: 5px;
  background-color: var(--container-light);
  border: 1px solid var(--primary-light);
  box-shadow: 0px 0px 2px 1px var(--primary-light);
  z-index: 99;
  position: absolute;
  width: 200px;
  top: 46px;
  left: 5px;
  display: inline-block;
  padding: 0;
  -webkit-app-region: no-drag;
}

.burger-menu-header{
  border-bottom: 1px solid var(--primary);
  height: 50px;
  width: 120px;
  overflow-x:hidden;
  margin:0;
}

.burger-menu-header-img {
  margin-top:5px;
  margin-left: 5px;
  height:40px;
  border-radius:50%;
  float: left;
}


.burger-menu-header-user {
  font-size: 1rem;
  text-overflow:clip;
  white-space: nowrap;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 60px;
  margin-right: 10px;
  width: max-content;
}

.burger-menu-item {
  cursor: pointer;
  margin: 0;
  font-size: 1.1rem;
  height: 30px;
  padding-top: 3px;
  border-bottom: 1px solid var(--primary);
  min-width: 100px;
}

.burger-menu-auth {
  padding: 0;
}

.burger-menu-item a {
  color: white;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.burger-menu-item:hover {
  border-radius: 5px;
  background-color: var(--secondary);
}

.burger-menu-item-icon {
  margin-top:5px;
  float:left;
}

.burger-menu-logo {
  margin-top:3px;
  margin-left:-2px;
  margin-right: -2px;
  height:18px;
  float: left;
}

.burger-menu-item-label {
  float: left;
  margin-left: 10px;
}