#Leaderboard {
    margin-top: 10px;
}

.team-name {
    font-size: 3em;
    margin: 0px;
}
.team-points {
    font-size: 3em;
    margin: 0px;
}

.team-wr {
    color: #ff3838;
}

.team-mg {
    color: #5353ff;
}
.leaderboard-table {
    border: none;
}
#Leaderboard .leaderboard-table .table {
    font-family: 'open sans', sans-serif;
    color: white;
}
.leaderboard-table tbody tr:nth-child(even) {
    background-color:#000000;
}
.leaderboard-table tbody tr:nth-child(odd) {
    background-color:#313131;
}
#Leaderboard .table.leaderboard-header{
    background-image: linear-gradient(#0D0D0D, #424242) !important;
    border-bottom:2px solid white;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.leaderboard-table tr:last-child{
    border-bottom:1px solid white;
}
/*
@font-face {
    font-family: "Xtreme Chrome";
    src: url(/fonts/xtrchr.ttf) format("truetype");
}*/
.team-name {
    font-family: "Xtreme Chrome", sans-serif;
    font-size: 20pt;
}
.team-points {
    font-family: "Xtreme Chrome", sans-serif;
    font-size: 20pt;
}

#Leaderboard .react-bs-table-pagination .btn-default {
    border-color: var(--highlight-color-1);
    color: white;
}

#Leaderboard .react-bs-table-pagination .dropdown-item:hover {
    background-color: var(--highlight-color-1);
    color: white;
}

#Leaderboard .pagination .page-item .page-link {
    color: white;
    background-color: var(--container-light);
    border-color: var(--primary);
}

#Leaderboard .pagination .page-item.active .page-link {
    background-color: var(--highlight-color-1);
    border-color: var(--highlight-color-1);
    color: white;
}