.message {
    border: 1px solid;
    border-color: var(--border-color);
    background-color: var(--background-color);
    border-radius: 4px;
    margin-bottom:5px;
}

.message-subject {
    text-align: left;
    cursor: pointer;
}

.message.unread{
    animation:blinkingText 0.8s infinite;
}
  
@keyframes blinkingText {
0%{		color: var(--text-color-2)	}
    49%{	color: var(--text-color-2)	}
    50%{	color: var(--text-color-2)	}
    99%{	color: #F00;	}
    100%{	color: #F00;	}
}

.no-messages {
    margin-top: 1rem;
}