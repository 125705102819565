.announcement {
    border: 1px solid;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;
    border-color: var(--border-color);
    color: var(--text-color-1);
}

.img-home {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

#countdownTimer {
    font-size: 80px;
}

.glow {
    font-size: 80px;
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000, 0 0 60px #0000, 0 0 70px #000;
    }
    
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000, 0 0 60px #000, 0 0 70px #000, 0 0 80px #000;
    }
  }