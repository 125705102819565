.challenge-header, .challenge-content, .challenge-input{
  border: 1px solid var(--border-color);
}

.challenge-content, .challenge-input, .challenge-name {
  text-align: left;
}

.challenge-header {
  background: var(--background-color);
  color: var(--text-color-2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.challenge-controls {
  text-align: right;
  cursor: pointer;
}

.challenge-content {
  border-top: 0px;
  border-bottom: 0px;
  padding: 20px;
}

.challenge-input {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px;
}

.challenge-input .form-group {
  margin-bottom: 0px;
}

.victory-text {
  color: lightgreen;
}

.incorrect-text {
  color: red;
}

#ArchiveChallengeWindow .btn-primary {
  background-color: var(--highlight-color-1);
  border-color: var(--highlight-color-1);
}

#ArchiveChallengeWindow .btn-primary:focus {
  outline: none;
  box-shadow: none;
}

.btn-default {
  background-color: var(--background-color);
  color: var(--text-color-2);
  border-color: var(--border-color);
}

.close-button{
  width: 20px;
  float: right;
}

.resistor {
  min-width: 80px;
}

.hidden-text {
  color: black;
  display:none;
}