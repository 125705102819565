.message-box {
    margin:20px;
  }
  
  .message-header, .message-content, .message-input{
    border: 1px solid var(--border-color);
  }
  
  .message-content, .message-input, .message-name {
    text-align: left;
  }
  
  .message-header {
    background: var(--background-color);
    color: var(--text-color-2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .message-controls {
    text-align: right;
    cursor: pointer;
  }
  
  .message-content {
    border-top: 0px;
    padding: 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .message-input {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px;
  }
  
  .message-input .form-group {
    margin-bottom: 0px;
  }
  
  .victory-text {
    color: lightgreen;
  }
  
  .btn-default {
    background-color: var(--background-color);
    color: var(--text-color-2);
    border-color: var(--border-color);
  }

  .close-button{
    width: 20px;
    float: right;
  }