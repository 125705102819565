.section-title{
  margin-top: 25px;
  font-size: 2em;
}

.achievement-unearned {
  opacity: 0.25;
}

.username {
  font-size: 3em;
  font-weight: bold;
}

.ach-earned {
  font-weight: bold;
}

.ach-unearned {
  opacity: 0.5;
}