#Secure {
  margin-top: 10px;
}

#Secure .input-group>.form-control {
  width: 100%;
}

#Secure .btn.btn-primary {
  margin: 5px auto;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#Secure .btn.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

#Secure .btn.btn-primary:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

#Secure .secure-message.disabled {
  display: none;
}