#BurgerMenuButton {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

@media (min-width :730px) {
  #BurgerMenuButton {
      display: none;
  }
}

#BurgerMenuButton .menu-button {
  padding: 2px 5px 0px 5px;
  /*
  border: 1px solid white;
  border-radius: 3px;*/
}

#BurgerMenuButton .unauthenticated-bar:focus {
  outline: none;
}

.burger-menu {
  font-size: 2rem;
  cursor: pointer;
}