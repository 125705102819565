#NavMenu {
  width: 100%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

@media (max-width :730px) {
  #NavMenu {
      display: none;
  }
}

#NavMenu .nav-menu-bar {
  display: block;
}

#NavMenu .nav-menu-bar .right-nav-links {
  float: right;
}

#NavMenu .nav-link {
  font-size: 0.9rem;
  color: var(--text-color-2);
  float:left;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.nav-link:focus {
  outline: none;
}

#NavMenu .nav-link:hover {
  color: var(--highlight-color-1);
}

#NavMenu .nav-link.discord-link {
  background-color:#7289DA;
  color: white;
  border-radius: 5px;
}
