#AppHeader {
  display: flex;
  padding: 0 20px;
  color: white;
  z-index:1000;
  background-color: var(--container-color);
  border-radius: 5px;
}

.navbar {
  margin-bottom: 0px;
  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--text-color-1);
  z-index:1000;
}

.justify-content-end {
  float: right;
}

.navbar-default .navbar-brand,.navbar-default .navbar-nav>li>a{
  color: var(--text-color-2);
}

.navbar-nav>li.discord-link>a {
  background-color:#7289DA;
  color: white;
  border-radius: 5px;
}

li.message-blinking>a{
  animation:blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0%{		color: var(--text-color-2)	}
	49%{	color: var(--text-color-2)	}
	50%{	color: var(--text-color-2)	}
	99%{	color: #F00;	}
	100%{	color: #F00;	}
}

.navbar-default .navbar-nav>.open>a:hover {
  color: var(--text-color-2);
  background-color: #939393;
}

.navbar-default .navbar-nav>.open>a {
  color: var(--text-color-1);
  background-color: var(--background-color);
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: var(--text-color-1);
}

.dropdown-menu>li>a {
  color: var(--text-color-1);
}
.open>.dropdown-menu{
  background-color: var(--background-color);
}
.navbar-default .navbar-nav .open .dropdown-menu>li>a {
  color: var(--text-color-1);
}

.navbar-default .navbar-nav .open .dropdown-menu>li.discord-link>a {
  background-color:#7289DA;
  color: white;
  border-radius: 5px;
}