.challenge-header, .challenge-content, .challenge-input{
  border: 1px solid var(--border-color);
}

.challenge-content, .challenge-input, .challenge-name {
  text-align: left;
}

.challenge-header {
  background: var(--background-color);
  color: var(--text-color-2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.challenge-controls {
  text-align: right;
  cursor: pointer;
}

.challenge-content {
  border-top: 0px;
  border-bottom: 0px;
  padding: 20px;
}

.challenge-input {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px;
}

.challenge-input .form-group {
  margin-bottom: 0px;
}

.victory-text {
  color: lightgreen;
}

.incorrect-text {
  color: red;
}

#ChallengeWindow .btn-primary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#ChallengeWindow .btn-primary:focus {
  outline: none;
  box-shadow: none;
}

#ChallengeWindow .btn-primary:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

#ChallengeWindow .challenge-image {
  width: 80%;
}

.btn-default {
  background-color: var(--background-color);
  color: var(--text-color-2);
  border-color: var(--border-color);
}

.close-button{
  width: 20px;
  float: right;
}

.resistor {
  min-width: 80px;
}

.hidden-text {
  color: black;
  display:none;
}

.masked-text {
  color: black;
}

.enbiggen {
    height: 90px;
    width: 90px;
    image-rendering: optimizeSpeed;            
    image-rendering: -moz-crisp-edges;       
    image-rendering: -o-crisp-edges;           
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;       
    -ms-interpolation-mode: nearest-neighbor; 
}