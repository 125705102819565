.challenge-bubble {
  background-color: var(--highlight-color-1);
  color: var(--text-color-1);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  min-height: 50px;
  min-width: 330px;
  padding: 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 5px;
}

.challenge-bubble.completed {
  background-color: var(--background-color);
}

.challenge-bubble-title {
  text-align: left;
}